// palette for Sahara Sands Casino
$bg-color: #0d2523;
$text-color: #fffaee;
$primary: #ffcc33;
$success: #63E85A;
$info: #BEA26A;
$danger: #F23207;


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

#rivalHeaderNavAndLogoSection {
  img {
    margin-top: -5px;
    margin-bottom: -5px;
  }
}


//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
}



#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}


#promos {
  img {
    width: 150% !important;
  }
}
